import React from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';

const Container = styled.div`
  text-align: center;
`;

const InstagramFeed = ({ isMobile }) => (
  <LazyLoad height={300} offset={1000} once>
    <Container>
      <script src="//lightwidget.com/widgets/lightwidget.js" />
      <iframe
        src="//lightwidget.com/widgets/f7913e637a8d565995ec55159a67704b.html"
        scrolling="no"
        allowTransparency="true"
        className="lightwidget-widget"
        style={{
          width: isMobile ? '100%' : '900px',
          height: isMobile ? '34wv' : '300px',
          border: '0',
          overflow: 'hidden',
        }}
      />
    </Container>
  </LazyLoad>
);

InstagramFeed.propTypes = {
  isMobile: PropTypes.bool,
};

InstagramFeed.defaultProps = {
  isMobile: false,
};

export default InstagramFeed;
