import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { Container, Content, Title1 } from '../components/Home/homeStyledComponents';
import MenuBar from '../components/Home/MenuBar';
import LaRubriquePostTemplate from './LaRubriquePostTemplate';
import SocialNetworks from '../components/Home/SocialNetworks';
import Footer from '../components/Home/Footer';
import Layout from '../layouts/index';

const Title2 = Title1.withComponent('h2');

const LaRubriquePostTemplateContainer = ({ data: { laRubriquePostsJson: post } }) => (
  <Layout routeSlug={`LaRubrique_${post.slug}`}>
    <Container>
      <Content>
        <MenuBar />
        <Title2>Rubrique Tilli</Title2>
        <LaRubriquePostTemplate post={post} />
        <SocialNetworks />
        <Footer />
      </Content>
    </Container>
  </Layout>
);

LaRubriquePostTemplateContainer.propTypes = {
  data: PropTypes.shape({
    laRubriquePostsJson: PropTypes.shape({
      title: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default LaRubriquePostTemplateContainer;

// eslint-disable-next-line no-undef
export const postTemplate2Page = graphql`
  query postTemplate2Query($path: String!) {
    laRubriquePostsJson(path: { eq: $path }) {
      id
      slug
      template
      path
      title

      keywords
      topImage {
        publicURL
      }
      topImageMobile {
        publicURL
      }
      topImageAlt
      topImageText
      topImageText2
      topImageTextStyle {
        fontSize
        margin
        fontSize2
        margin2
      }
      topImageTextMobileStyle {
        fontSize
        margin
        fontSize2
        margin2
      }
      row1Text
      row1Image {
        publicURL
      }
      row1ImageAlt
      row2Text
      row2Image {
        publicURL
      }
      row2ImageMobile {
        publicURL
      }
      row2ImageAlt
      row2ImageTopMargin
      row2ImageLeftMargin
      p2LeftMargin
      row2ImageText
      row2ImageTextStyle {
        fontSize
        textAlign
        margin
      }
      row2ImageTextMobileStyle {
        fontSize
        textAlign
        margin
      }

      topBlock {
        keywords
        keywordsContainerMargin
        keywordsContainerMarginMobile
        topImage {
          src { publicURL }
          alt
        }
        topImageText
        topImageTextStyle {
          fontSize
          margin
        }
        topImageTextMobileStyle {
          fontSize
          margin
        }
        lineHMargin
        row1Text
        row1TextMargin
        row1TextMarginMobile
        row1Image {
          src { publicURL }
          alt
        }
      }
      middleBlock {
        rows {
          image {
            src { publicURL }
            alt
          }
          text1
          text2
        }
      }
      bottomBlock {
        row4Image {
          src { publicURL }
          alt
        }
        row4Text
        row4TextMargin
      }
    }
  }
`;
