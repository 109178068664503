import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { colors, margins, fontSizes, mobileThresholdPixels, contentWidth }
  from '../../components/Home/v2/StyledComponents';

const Container = styled.div`
  color: ${colors.navy};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${margins.m} 0px;
`;

const Content = styled.div`
  width: ${contentWidth}px;
  display: flex;
  flex-direction: ${props => props.reverse ? 'row-reverse' : 'row'};
  justify-content: center;
  align-items: center;
  margin-bottom: ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: ${margins.l};
    flex-wrap: wrap;
    align-items: center;
    width: 100vw;
  }
`;

const Title3 = styled.h3`
  font-size: ${fontSizes.x2l};
  white-space: pre;
  font-weight: 500;
  margin: 0px 0px ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.l};
    margin-bottom: ${margins.m};
  }
`;

const ImgContainer = styled.div`display: flex;`;

const Img = styled.img`
  z-index: 1;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 90%;
    object-fit: cover;
    margin: auto;
  }
`;

const TextContainer = styled.div`
  width: 50%;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
  }
`;

const P = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightGrey};
  padding: ${margins.m};
  margin: ${margins.m};
  height: 80px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 130px;
  }
`;

const NumerotedSpan = styled.span`
  font-weight: 500;
  font-size: ${fontSizes.x4l};
  color: ${colors.yellow};
  margin: -50px 15px 0px -15px;
`;

const NumerotedP = (index, text) => (
  <P>
    <NumerotedSpan>{`${index}.`}</NumerotedSpan>
    {text}
  </P>
);

const LaRubriqueTemplate2MiddleBlock = ({ rows }) => (
  <Container>
    <Title3>•  Instructions  •</Title3>
    {rows.map((row, index) =>
      <Content reverse={index % 2 !== 0} key={row.text1}>
        <ImgContainer>
          <Img
            src={row.image.src.publicURL}
            alt={row.image.alt}
          />
        </ImgContainer>
        <TextContainer>
          {NumerotedP((index * 2) + 1, row.text1)}
          {NumerotedP((index + 1) * 2, row.text2)}
        </TextContainer>
      </Content>,
    )}
  </Container>
);

LaRubriqueTemplate2MiddleBlock.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.shape({ publicURL: PropTypes.string }).isRequired,
      alt: PropTypes.string.isRequired,
    }).isRequired,
    text1: PropTypes.string.isRequired,
    text2: PropTypes.string.isRequired,
  })).isRequired,
};

export default LaRubriqueTemplate2MiddleBlock;
